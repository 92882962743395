<template>
  <div class="grid grid-cols-12 gap-4">
    <!-- listado  -->
    <listado/>
    <!-- Formulario  -->
    <platilla/>
  </div>
</template>
<script>
import { getListDepartment } from './hooks'
import platilla from './components/platilla'
import listado from './components/listado'
import { onMounted } from 'vue'
export default {
  components: {
    platilla,
    listado
  },
  setup () {
    onMounted(() => {
      getListDepartment()
    })
    return {
      getListDepartment
    }
  }
}
</script>
