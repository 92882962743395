<template>
  <div class="2xl:col-span-3 lg:col-span-4 md:col-span-12 sm:col-span-12 col-span-12 mt-6 box" style="height: 350px;">
    <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-base mr-auto">
        {{titleForm}}
      </h2>
    </div>
    <div id="departments-form relative" class="p-5">
      <div class="preview" style="display: block; opacity: 1;">
        <div>
          <label for="departments-form-1" class="form-label">
            Código
          </label>
          <input id="departments-form-1" type="number" class="form-control" placeholder="" v-model="formDepartments.id">
        </div>
        <div class="mt-3">
          <label for="departments-form-2" class="form-label">
            Nombre
          </label>
          <input id="departments-form-2" type="text" class="form-control" placeholder="" v-model="formDepartments.name">
        </div>
        <div class="absolute bottom-5 right-5 flex space-x-4">
          <button class="btn btn-danger mt-5" @click="handleCancel">
            Cancelar
          </button>
          <button class="btn btn-primary mt-5" @click="handleRegister">
            Registrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isCreate, titleForm, formDepartments, handleRegister, handleCancel } from '../../hooks'
export default {
  setup () {
    return {
      isCreate, titleForm, formDepartments, handleRegister, handleCancel
    }
  }
}
</script>

<style scoped>

</style>
