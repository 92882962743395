<template>
  <div class="2xl:col-span-9 lg:col-span-8 md:col-span-12 sm:col-span-12 col-span-12 mt-6 box">
    <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-base mr-auto">
        Listado de departamentos
      </h2>
    </div>
    <div class="flex flex-wrap p-5">
      <div v-for="department in departments.data" :key="department.id" class="el-v-for box px-5 py-3 mb-3 mx-3 zoom-in flex-grow xl:flex-grow-0">
        <div class="flex">
          <div class="ml-4 mr-auto">
            <div class="font-medium">
              {{department.name}}
            </div>
            <div class="text-gray-600 text-xs mt-0.5">
              Código: {{department.id}}
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex justify-center items-center">
              <a class="flex items-center mr-3 text-theme-10" title="Editar" @click="loadFormDepartmens(department)">
                <edit-icon size="1.5x" class="custom-class"/>
              </a>
              <a class="flex items-center text-theme-6" title="Eliminar" @click="deleteItem(department)">
                <delete-icon size="1.5x" class="custom-class"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- paginador -->
    <Paginator
      :current-page="paginate.current_page"
      :total="paginate.total"
      :last-page="paginate.last_page"
      @change-page="getListDepartment"
    />
  </div>
</template>
<script>
import { departments, loadFormDepartmens, deleteItem, getListDepartment, paginate } from '../../hooks'
// import paginador from '../paginador'
export default {
  setup () {
    return {
      departments, loadFormDepartmens, deleteItem, paginate, getListDepartment
    }
  }
}
</script>
<style scoped>
@media screen  and (min-width: 625px) {
  .el-v-for{
    min-width: 300px;
    min-height: 60px;
  }
}
</style>
