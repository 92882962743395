import { computed, reactive, ref } from 'vue'
import create from './services/create'
import { confirm, error, success } from '../../../../../libs/mensajes'
import remove from './services/remove'
import listAll from './services/listAll'
import Update from './services/update'
// DATA
const isCreate = ref(true)
const titleForm = ref('Crear o actualizar departamento')
const departments = ref({
  data: [],
  current_page: 0,
  last_page: 0,
  per_page: 0,
  total: 0
})
const formDepartments = reactive({
  id: null,
  name: null
})
// COMPUTED
const paginate = computed(() => {
  return {
    current_page: departments.value.current_page,
    last_page: departments.value.last_page,
    per_page: departments.value.per_page,
    total: departments.value.total
  }
})
// METHODS
const handleRegister = () => {
  if (isCreate.value) {
    create(formDepartments).then((response) => {
      console.log('create', response)
      if (response.statusCode === 409) {
        error(response)
        console.error(response.data.message, 'Error')
      } else {
        success(response.data.message, 'Éxito')
        clearFormRequest()
        getListDepartment()
      }
    }).catch((error) => {
      console.error(error)
    })
  } else {
    updateDepartments(formDepartments)
  }
  isCreate.value = true
}
const loadFormDepartmens = (department) => {
  formDepartments.id = department.id
  formDepartments.name = department.name
  isCreate.value = false
}
const deleteItem = (department) => {
  confirm(`Eliminar el departamento ${department.name}`).then(({ isConfirmed }) => {
    if (isConfirmed) {
      remove(department.id).then(({ status, data, statusText }) => {
        if (status === 200) {
          success(data.message, 'Éxito')
          getListDepartment()
        } else {
          error(data.message, 'Error')
          console.error(statusText)
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  })
}
const getListDepartment = (page) => {
  isCreate.value = true
  listAll({ page, paginate: 30, order: 'name,asc' }).then(({ data }) => {
    departments.value = data
  }).catch((error) => {
    console.error(error)
  })
}
const updateDepartments = (department) => {
  isCreate.value = true
  Update(department).then((response) => {
    console.log('updateDepartments', response)
    if (response.status === 200) {
      success('departamento actualizado', 'Éxito')
      clearFormRequest()
      getListDepartment()
    } else {
      error('a ocurrido un error al actualizar el departamento', 'Error')
      console.error(response.statusText)
    }
  }).catch((error) => {
    console.error(error)
  })
  getListDepartment()
}
const handleCancel = () => {
  formDepartments.id = null
  formDepartments.name = null
  isCreate.value = true
}
const clearFormRequest = () => {
  formDepartments.id = null
  formDepartments.name = null
}
export {
  departments,
  handleRegister,
  loadFormDepartmens,
  deleteItem,
  updateDepartments,
  handleCancel,
  isCreate,
  titleForm,
  formDepartments,
  getListDepartment,
  paginate
}
